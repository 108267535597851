<template>
  <!--header开始-->
  <header class="header-lg">
    <div class="header-gk-container gk-clear">
      <div class="logo">
        <router-link to="/">
          <img src="/static/images/logo.png" alt="">
        </router-link>
      </div>
      <div class="nav">
        <ul>
          <li v-for="(m,index) in nav_mian" v-bind:key="index">
            <router-link :to="m.url" active-class="active">{{ m.name }}</router-link>
            <div class="sub-nav">
              <div class="mod">
                <div class="mod-tit">行业类型</div>
                <div class="cont">
                  <span v-for="n in business_data" :key="n.id" @click="this.$router.push(m.url)">{{ n.name }}</span>
                </div>
              </div>
              <div class="mod">
                <div class="mod-tit">品牌厂商</div>
                <div class="cont">
                  <span v-for="n in brand_data" :key="n.id" @click="this.$router.push(m.url)">{{ n.name }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="nav-ai" @click="showAi">
        <img src="/static/images/img-k.svg" alt="">
        <span>你好,我是小K</span>
      </div>
      <div class="header-solution g-clear" @keyup.enter.down="keyDownSearch">
        <div class="select">
          <el-select v-model="ident" style="width:120px;background-color: transparent;">
            <el-option v-for="item in nav_mian" v-bind:key="item.id" :label="item.name" :value="item.url"></el-option>
          </el-select>
        </div>
        <!-- <div class="select">
          <span>解决方案</span>
          <img src="/static/iconImg/arrow-down-s-line.png" alt="">
        </div> -->
        <input class="inputmod" type="text" v-model="keyword" placeholder="搜索解决方案"/>
        <div class="btn" @click="search">
          <img src="/static/iconImg/search.png" alt="">
        </div>
      </div>
      <div class="op-btn gk-clear">
        <div :class="account.username ? 'item mls':'item'">
          <router-link to="/publish">
            <img src="/static/iconImg/upload-cloud-line.png" alt=""><span>上传</span>
          </router-link>
        </div>
<!--    <div :class="account.username ? 'item mls':'item'">
          <router-link to="/user/home">
            <img src="/static/iconImg/vip-crown-line.png" alt=""><span>会员</span>
            <div class="tips">首充3折</div>
          </router-link>
        </div>-->
        <div :class="account.username ? 'item mls':'item'" v-if="!account.username">
          <a href="javascript:;" class="J-login" @click="showLogin">
            <img src="/static/iconImg/outlet-line.png" alt=""><span>注册/登录</span>
          </a>
        </div>
        <div :class="account.username ? 'item mls':'item'" v-if="account.username">
          <router-link to="/user/home" class="J-login">
            <img src="/static/iconImg/outlet-line.png" alt=""><span style="margin-right:10px;">{{ account.nickname }}</span>
          </router-link>
          <a href="javascript:;" class="J-login" @click="logout" >
            <img src="/static/iconImg/outlet-line.png" alt=""><span>退出登录</span>
          </a>
        </div>
      </div>
    </div>
  </header>
  <!--header结束-->
  <!-- AI智能助手弹窗 -->
  <div class="pop-ai" v-if="aiIsShow">
    <div class="item-ai">
      <img src="/static/images/img-k.svg" alt="">
      <div class="cont">
        <div class="name">AI智能助手-小K</div>
        <div class="txt">
          <p>你好！欢迎使用咋办方案AI智能助手，我是您的助手小K，我拥有以下独特能力：</p>
          <p>•回答你的一切问题，在大模型的基础上，我集成了全网搜索结果，提供更加可信的回答</p>
          <p>•帮你创作、提供灵感，包括社交媒体文案/生成图片/写周报</p>
          <p>•根据你的个性需求辅助你做决策</p>
        </div>
      </div>
    </div>
    <div class="item-me">
      <span>生成一份智慧农业方案</span>    
    </div>
    <div class="item-ai">
      <img src="/static/images/img-k.svg" alt="ikeyhub 小K">
      <div class="cont">
        <div class="name">AI智能助手-小K</div>
        <div class="txt">
          <p>这是我为您生成的智慧农业方案。</p>
          <div class="file-mod">
            <img src="/static/images/img-pdf.png" alt="">
            <div class="file-info">
              <div class="file-tit">智慧农业方案/ikeyhub.pdf</div>
              <div class="file-num">2.5M</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="input-mod">
      <el-input type="textarea" placeholder="在此输入您想了解的内容，小K会帮您解答..." resize="none" rows="4" v-model="aiInput"></el-input>
      <div class="input-btns">
        <div class="btn-send">发送</div>
      </div>
    </div>
    <div class="tips">内容由ikeyhub咋办大模型生成，仅供您参考</div>
  </div>
</template>

<script>
import article from "@/api/article";
import sys from "@/api/system";
import user from "@/api/user";
import {mapState} from "vuex";

export default {
  name: "header",
  computed: {
    ...mapState(['account'])
  },
  data() {
    var validPwd = (rule, value, callback) => {
      //密码只能由大小写英文字母或数字开头，且由大小写英文字母_.组成
      const reg = /^[A-Za-z0-9][A-Za-z0-9_.]{5,17}$/
      if (!value.match(reg)) {
        callback(new Error('账号由字母或数字开头，且只能为字母,数字,下划线及（.）长度在 6 到 18 个字符'))
      } else {
        callback()
      }
    }
    var validRePwd = (rule, value, callback) => {
      if (value !== this.pwdform.password) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      nav_mian: [],
      is_show: false,
      formVisible: false,
      pwdFormVisible: false,
      crumb: [],//面包屑
      keyword: '',
      ident:'/solution',
      form: {
        realname: '',
        mobile: '',
        email: '',
        avatar: ''
      },
      pwdform: {
        oldpassword: '',
        password: '',
        repassword: ''
      },
      rules: {
        oldpassword: [{required: true, message: '请输入旧密码', trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}, {validator: validPwd, trigger: 'blur'}],
        repassword: [{required: true, message: '请输入确认密码', trigger: 'blur'}, {
          validator: validRePwd,
          trigger: 'blur'
        }],
        realname: [{required: true, message: '请输入真实姓名', trigger: 'blur'}, {
          min: 1,
          max: 25,
          message: '长度在 1 到 25 个字符',
          trigger: 'blur'
        }]
      },
      business_data: [],
      brand_data: [],
      aiInput:'',
      aiIsShow:false
    }
  },
  created() {
    this.getNavMainList();
    this.getBusinessData();
    this.getBrandData();
  },
  mounted() {
  },
  watch: {
    $route: {
      handler() {
        this.crumb = this.getCrumb();
      },
      immediate: true
    }
  },
  methods: {
    //enter键提交
    keyDownSearch(e) {
      //console.log('enter');
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.search();
      }
    },
    //登录框
    showLogin() {
      this.$store.dispatch('toggleLoginModal');
    },
    //导航调取
    getNavMainList() {
      sys.getNavMainList({
        type: 'pc'
      }).then(res => {
        if (res) {
          this.nav_mian = res.data;
        } else {
          console.log(res);
        }
      })
    },
    //行业 筛选
    getBusinessData() {
      article.getCategoryNameList({
        ident: 'business'
      }).then(res => {
        this.business_data = res.data;
      })
    },
    //品牌 筛选
    getBrandData() {
      article.getCategoryNameList({
        ident: 'brand'
      }).then(res => {
        this.brand_data = res.data;
      })
    },
    search() {
      this.$store.commit("updateKeyword", this.keyword);
      this.$router.push(this.ident);
      // this.$router.push("/solution");
      // this.$router.push("/standard");
    },
    //面包屑
    getCrumb() {
      // 根据路由配置meta中的showInbreadcrumb字段过滤
      let crumbArr = this.$route.matched.filter((item) => {
        return item.meta.title
      });
      if (crumbArr[0].meta.title !== '主页') {
        crumbArr.unshift(
            {
              path: '/',
              name: 'default',
              meta: {
                title: "首页"
              }
            },
        )
      }
      return crumbArr;
    },
    //二级菜单显示与隐藏
    dropNav() {
      this.is_show = !this.is_show;
    },
    //退出
    async logout() {
      //管理后台登录
      user.logout({
        ident: 'logout'
      }).then(res=> {
        if (res) {
          this.$store.dispatch('clearAccount');
          this.$message.success(res.msg);
          this.$router.push('/');
        }
      })
    },
    //弹出基本资料
    showFrom() {
      this.formVisible = true;
      this.dropNav();
    },
    //关闭-弹出基本资料
    closeFrom() {
      this.formVisible = false;
    },
    //修改基本资料
    async submit() {
      const that = this;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          user.editLoginAdmin(that.form).then(function (res) {
            if (res) {
              this.$store.dispatch('setAccount',res.data);
              that.$message.success(res.msg);
            }
            that.formVisible = false;
          });
        } else {
          return false
        }
      })
    },
    //弹出修改密码
    showPwdFrom() {
      this.pwdFormVisible = true;
      this.dropNav();
    },
    //关闭-弹出修改密码
    closePwdFrom() {
      this.pwdFormVisible = false;
    },
    //修改密码
    async submitPwd() {
      const that = this;
      this.$refs['pwdform'].validate((valid) => {
        if (valid) {
          user.editLoginPwd(that.pwdform).then(function (res) {
            if (res) {
              that.$message.success(res.msg)
            }
            that.pwdFormVisible = false;
          });
        } else {
          return false
        }
      })
    },
    //清除缓存
    clearCache() {
      const that = this;
      sys.clearCache({
        param: 'no'
      }).then(function (res) {
        if (res) {
          that.$message.success(res.msg)
        }
        that.dropNav();
      });
    },
    // 显示ai对话框
    showAi(){
      this.aiIsShow = !this.aiIsShow
    }
  }
}
</script>
<style lang="scss" scoped>
.this-header {
  display: flex;
  align-items: center;
  position: relative;
  height: 60px;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 -1px 0 #eee inset;
  transition: padding-left .3s cubic-bezier(.2, 0, 0, 1) 0s, box-shadow .3s cubic-bezier(.2, 0, 0, 1) 0s;

  // 面包屑
  .crumb {
    height: 45px;
    line-height: 45px;
    padding: 0 20px;
    background: #fff;

    .tit {
      float: left;
      color: #333;
      vertical-align: middle;
    }

    .el-breadcrumb {
      float: left;
      height: 45px;
      line-height: 45px;
      padding-left: 5px;
    }
  }

  .header-right {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 20px;
    color: #000;
    border-radius: 2px;

    .item {
      position: relative;
      display: inline-block;
      line-height: 60px;

      a {
        display: block;
        padding: 0 20px;
        color: rgba(0, 0, 0, .7);
      }

      .more {
        position: absolute;
        top: 50%;
        right: 3px;
        margin-top: -3px;
        width: 0;
        height: 0;
        content: '';
        border-width: 6px;
        border-style: dashed;
        boder-top-style: solid;
        border-color: transparent;
        border-top-color: #409eff;
        cursor: pointer;

        &.show {
          margin-top: -9px;
          border-style: dashed;
          border-color: transparent;
          border-bottom-style: solid;
          border-bottom-color: #409eff;
        }
      }

      .subnav {
        position: absolute;
        left: 0;
        top: 80px;
        min-width: 100%;
        line-height: 36px;
        padding: 5px 0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
        border: 1px solid #d2d2d2;
        background: #fff;
        z-index: 100;
        border-radius: 2px;
        white-space: nowrap;
        opacity: 0;
        transition: all .5s;
        -webkit-transition: all .5s;

        dd a {
          color: #333;

          &:hover {
            background: #f2f2f2;
            color: #000;
          }
        }

        &.show {
          top: 65px;
          opacity: 1;
        }
      }

      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 5px;
        background-color: #409eff;
        transition: all .2s;
        -webkit-transition: all .2s;
        content: '';
        //&:hover{ }
      }

      &:hover::after {
        width: 100%;
      }

    }
  }
 
}
::v-deep .select .el-select .el-select__wrapper{background-color:transparent;box-shadow: none !important;font-size: 16px;color:#000;}
::v-deep .select .el-select .el-select__wrapper .el-select__placeholder{color:#000;}
::v-deep .select .el-select .el-select__caret{color:#000;}
.pop-ai{position: fixed;right:0;top:0;z-index:999;width:660px;padding:20px;background: #fff;border-left:2px solid #C2F41F;box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.1);
  .item-ai{display: flex;align-items: flex-start;margin-bottom:20px;
    img{width:60px;height: 60px;margin-right:10px;}
    .cont{
      .name{color: #000000;font-size: 16px;height:30px;line-height:35px;}
      .txt{width:515px;padding:20px 36px 30px 19px;background:url('/static/images/ai-chat-bg.png') -15px 0 no-repeat;background-size:100% 100%;font-family: Source Han Sans;color: #333333;font-size: 16px;line-height: 28px;
        .file-mod{display: flex;align-items: center;margin-top:15px;
          img{width:60px;height: 60px;margin-right:10px;}
          .file-info{font-family: Source Han Sans;
            .file-tit{color: #333333;font-size: 18px;line-height: 20px;margin-bottom:5px;}
            .file-num{color: #666666;font-size: 16px;}
          }
        }
    }
    }
  }
  .item-me{display:flex;justify-content:flex-end; 
    span{padding:10px 30px;background: #C2F41F;color: #333333;line-height: 28px;font-size: 16px;}
  }
  .input-mod{background: #FFFFFF;box-sizing: border-box;border: 1px solid #000000;height: 180px;margin-top:60px;margin-bottom:30px; border-radius: 8px;padding:10px 10px 20px;
    :deep(.el-textarea__inner){box-shadow: none;font-size: 16px;}
    .input-btns{display: flex;justify-content: flex-end;margin-top:8px;
      .btn-send{width: 88px;height: 40px;line-height:40px;text-align:center;border-radius: 6px;background: #C1F421;cursor:pointer;color: #000000;font-size: 18px;}
    }
  }
  .tips{color: #999999;font-size: 14px;line-height: 16px;text-align: center;}
}
</style>
